import React, { useEffect } from 'react';
import productDevelopmentImage from '../../../assets/product-development.jpg'; // Make sure you have this image or update the path accordingly
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const ProductDevelopmentManagement = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);
  
  return (
    <div className="product-development-management font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${productDevelopmentImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Product Development and Management</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            Transform your product vision into a market-ready reality. I will guide you through the entire product development lifecycle, from ideation and concept validation to agile development and launch. By implementing proven methodologies and best practices, we'll streamline your development process, foster collaboration, and accelerate time-to-market. We'll leverage data-driven insights to continuously optimize your product, ensuring it meets the evolving needs of your customers and delivers exceptional user experiences.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Who is it for:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Startups looking to build their first product from scratch.</li>
            <li>Established businesses seeking to launch new products or enhance existing ones.</li>
            <li>Companies struggling with inefficient product development processes or slow time-to-market.</li>
            <li>Organizations looking to adopt agile methodologies and improve team collaboration.</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What's achieved:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Innovative Product Concepts: Generate and validate new product ideas that resonate with your target market.</li>
            <li>Streamlined Development: Implement agile practices to accelerate development cycles and improve team productivity.</li>
            <li>Optimized User Experience: Leverage data analytics to understand user behavior and enhance product usability.</li>
            <li>Successful Product Launches: Develop and execute go-to-market strategies that drive adoption and maximize impact.</li>
            <li>Continuous Improvement: Establish a culture of data-driven decision-making and continuous product optimization.</li>
          </ul>
          <p className="text-lg mb-8">
            By partnering with me, you'll gain a seasoned product leader who can help you navigate the complexities of product development and management, ensuring your product is not only built efficiently but also delivers exceptional value to your customers.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default ProductDevelopmentManagement;
