import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import consultingImage from '../../../assets/consulting.jpg';
import { useLocation } from 'react-router-dom';

const ConsultingServices = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="consulting-services font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${consultingImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Consulting Services</h1>
          <p className="text-2xl mt-4">Empowering Businesses Through Strategic Product Leadership</p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            With over 20 years of experience leading product teams and driving growth at global tech companies like Polaroid and TCL Communication, I offer a comprehensive suite of consulting services designed to empower your business. Whether you're a startup seeking to launch your first product or an established company looking to optimize your existing portfolio, I can provide the strategic guidance and tactical expertise you need to succeed.
          </p>
          <p className="text-lg mb-4">
            My consulting services encompass the entire product lifecycle, from ideation and planning to development, launch, and ongoing optimization. I specialize in crafting winning product strategies, conducting in-depth market and competitive analysis, and building detailed product roadmaps that align with your business goals. I also offer expertise in pricing strategy, ensuring your product is positioned for maximum profitability and market success.
          </p>
          <p className="text-lg mb-4">
            In addition to strategic planning, I can help you streamline your product development process, implement agile methodologies, and leverage data-driven insights to optimize your product's performance. I can also guide you through the complexities of digital transformation, helping you leverage emerging technologies to drive innovation and gain a competitive edge.
          </p>
          <p className="text-lg mb-4">
            By partnering with me, you'll gain a trusted advisor who is passionate about helping businesses achieve their full potential. I bring a wealth of experience, a proven track record of success, and a deep understanding of the tech industry to every engagement.
          </p>
          <p className="text-lg mb-8">
            Explore my consulting services and discover how I can help you achieve your product goals.
          </p>
        </div>
      </section>
      
      {/* Services List Section */}
      <section className="services-list py-20 bg-gray-900 text-center">
        <div className="container mx-auto max-w-screen-lg">
          <h2 className="text-3xl font-heading font-bold text-white mb-6">Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Strategic Product Planning & Roadmapping</h3>
              <p className="text-white mb-4">Helps businesses define their product vision, strategy, and roadmap for success.</p>
              <Link to="/consulting-services/strategic-product-planning" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Pricing Strategy</h3>
              <p className="text-white mb-4">Develops data-driven pricing strategies that maximize profitability and align with customer value.</p>
              <Link to="/consulting-services/pricing-strategy" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Product Development & Management</h3>
              <p className="text-white mb-4">Guides businesses through the entire product lifecycle, from ideation to launch and optimization.</p>
              <Link to="/consulting-services/product-development-management" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Operational Excellence</h3>
              <p className="text-white mb-4">Streamlines operations, reduces costs, and improves efficiency through process optimization and data-driven insights.</p>
              <Link to="/consulting-services/operational-excellence" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Digital Transformation</h3>
              <p className="text-white mb-4">Helps businesses leverage emerging technologies and innovative solutions to drive growth and gain a competitive edge.</p>
              <Link to="/consulting-services/digital-transformation" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ConsultingServices;
