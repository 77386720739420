import React from 'react';

const Sidebar = ({ categories, selectedCategory, onSelectCategory }) => {
  return (
    <div className="sidebar bg-gray-800 text-white p-4">
      <h2 className="text-xl font-heading font-semibold mb-4">Categories</h2>
      <ul className="space-y-2">
        {categories.map((category, index) => (
          <li key={index}>
            <button 
              onClick={() => onSelectCategory(category)}
              className={`block w-full text-left p-2 rounded ${selectedCategory === category ? 'bg-gray-600' : 'hover:bg-gray-700'}`}>
              {category}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
