import React, { useEffect } from 'react';
import { getCalApi } from "@calcom/embed-react";
import contactHeroImage from '../../assets/contact-hero.jpg';
import { useLocation } from 'react-router-dom';

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="contact font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${contactHeroImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl md:text-6xl font-heading font-bold">Achieve Product Excellence</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="flex flex-col justify-center items-center px-4 py-20">
        <div className="max-w-2xl text-center">
          <p className="text-lg mb-6">
            I'm passionate about helping businesses like yours achieve their full potential. Let's discuss how my proven strategies in product planning, development, and management can drive tangible results for your organization. Book a free consultation today to get started.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Book Your Free Consultation
          </button>
        </div>
      </section>
    </div>
  );
};

export default Contact;