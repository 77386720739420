import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import Blog from './components/Blog/Blog';
import BlogPost from './components/Blog/BlogPost';
import Contact from './components/Contact/Contact';
import Navbar from './components/Navbar/Navbar';
import ConsultingServices from './components/Services/Consulting Services/ConsultingServices';
import StrategicProductPlanning from './components/Services/Consulting Services/StrategicProductPlanning';
import PricingStrategy from './components/Services/Consulting Services/PricingStrategy';
import ProductDevelopmentManagement from './components/Services/Consulting Services/ProductDevelopmentManagement';
import OperationalExcellence from './components/Services/Consulting Services/OperationalExcellence';
import DigitalTransformation from './components/Services/Consulting Services/DigitalTransformation';
import CoachingServices from './components/Services/Coaching Services/CoachingServices';
import FractionalCPOCOO from './components/Services/Coaching Services/FractionalCPOCOO';
import ExecutiveCoaching from './components/Services/Coaching Services/ExecutiveCoaching';
import ProductLeadershipCoaching from './components/Services/Coaching Services/ProductLeadershipCoaching';

const RoutesComponent = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/consulting-services" element={<ConsultingServices />} />
        <Route path="/consulting-services/strategic-product-planning" element={<StrategicProductPlanning />} />
        <Route path="/consulting-services/pricing-strategy" element={<PricingStrategy />} />
        <Route path="/consulting-services/product-development-management" element={<ProductDevelopmentManagement />} />
        <Route path="/consulting-services/operational-excellence" element={<OperationalExcellence />} />
        <Route path="/consulting-services/digital-transformation" element={<DigitalTransformation />} />
        <Route path="/coaching-services" element={<CoachingServices />} />
        <Route path="/coaching-services/fractional-cpo-coo" element={<FractionalCPOCOO />} />
        <Route path="/coaching-services/executive-coaching" element={<ExecutiveCoaching />} />
        <Route path="/coaching-services/product-leadership-coaching" element={<ProductLeadershipCoaching />} />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;

