import React, { useEffect } from 'react';
import fractionalCPOCOOImage from '../../../assets/fractional-cpocoo.jpg'; // Ensure this path is correct
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const FractionalCPOCOO = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="fractional-cpocoo font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${fractionalCPOCOOImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Fractional CPO/COO Services</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            Empower your business with strategic product leadership and achieve sustainable growth. I offer a range of services tailored to your specific needs and goals, ensuring you receive the maximum value and impact.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Fractional CPO/COO Services</h2>
          <p className="text-lg mb-4">
            Gain the expertise of a seasoned executive without the commitment of a full-time hire. As your Fractional CPO or COO, I provide strategic leadership and operational guidance on a flexible, part-time basis. Whether you need to navigate a critical transition, fill a temporary leadership gap, or accelerate specific initiatives, I can step in and provide the experience and expertise you need to achieve your goals.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What I offer:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Strategic planning and execution</li>
            <li>Product roadmap development and prioritization</li>
            <li>Go-to-market strategy development</li>
            <li>Team leadership and mentorship</li>
            <li>Operational efficiency and optimization</li>
            <li>Performance improvement initiatives</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Achieve your goals with:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Experienced leadership</li>
            <li>Strategic guidance</li>
            <li>Improved performance</li>
            <li>Accelerated growth</li>
            <li>Cost-effective solutions</li>
            <li>Flexibility to meet your evolving needs</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Add-on: Executive Coaching</h2>
          <p className="text-lg mb-4">
            Enhance your leadership skills and achieve your full potential through personalized coaching. As your executive coach, I will help you develop your strategic thinking, decision-making, communication, and team-building skills. We'll work together to identify your strengths and weaknesses, set goals, and create a plan to achieve them.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Benefits of executive coaching:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Improved leadership skills</li>
            <li>Enhanced performance</li>
            <li>Personal growth and development</li>
            <li>Increased confidence and resilience</li>
          </ul>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default FractionalCPOCOO;
