import React, { useEffect } from 'react';
import aboutHeroImage from '../../assets/about-hero.jpg'; // Ensure this path is correct
import profileImage from '../../assets/ogi-grba.jpg'; // Ensure this path is correct
import { getCalApi } from "@calcom/embed-react";
import { FaLinkedin } from 'react-icons/fa'; // LinkedIn icon
import { useLocation } from 'react-router-dom';

const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="about font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${aboutHeroImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4 max-w-4xl mx-auto">
          <div className="appear mt-4">
            <p className="quote text-4xl">"Anyone who has never made a mistake has never tried anything new"</p>
            <p className="signature text-xl mt-2">- Albert Einstein</p>
          </div>
        </div>
      </section>

      <style jsx>{`
        .appear {
          opacity: 0;
          animation: appear 2s forwards;
        }

        @keyframes appear {
          to {
            opacity: 1;
          }
        }
      `}</style>

      {/* Profile Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg relative">
          <div className="relative inline-block">
            <img src={profileImage} alt="Ogi Grba" className="rounded-full mx-auto w-40 h-40 object-cover mb-6" />
            <a href="https://linkedin.com/in/ogrba" target="_blank" rel="noopener noreferrer" className="absolute bottom-0 right-0 text-white text-2xl hover:text-gray-400">
              <FaLinkedin />
            </a>
          </div>
          <p className="text-lg mb-4 max-w-2xl mx-auto">
            I’m Ogi Grba, a dynamic and visionary Product Leader with over 20 years of experience in driving strategic innovation and digital transformation across global tech industries. My journey has been marked by leading companies through pivotal growth phases, from concept development to achieving market leadership, all through agile leadership and customer-centric product design.
          </p>
          <p className="text-lg mb-4 max-w-2xl mx-auto">
            Throughout my career, I’ve specialized in various core competencies, including strategic planning, leadership and team development, product management, technology and engineering, project management, marketing and sales, and consulting and advisory. My expertise spans multiple industries such as consumer electronics, enterprise software, legal tech solutions, and connected worker solutions, to name a few.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Professional Highlights:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Strategic Visionary: I have successfully developed and executed robust business strategies, driving substantial revenue growth and market expansion.</li>
            <li>Product Innovator: Leading the creation and launch of cutting-edge digital products and solutions, ensuring alignment with market needs and customer satisfaction.</li>
            <li>Leadership Excellence: Building and mentoring high-performing teams, fostering a culture of innovation and operational efficiency.</li>
            <li>Consulting Expertise: Providing strategic consulting and advisory services, enhancing global competitiveness and operational efficiency for my clients.</li>
          </ul>
          <p className="text-lg mb-4 max-w-2xl mx-auto">
            A significant part of my professional life is dedicated to coaching and mentoring. I am passionate about guiding teams and individuals towards achieving their full potential. As a certified Entrepreneurship and Business Coach, I have helped numerous professionals and startups navigate their growth journeys.
          </p>
          <p className="text-lg mb-4 max-w-2xl mx-auto">
            I’ve held pivotal roles such as Co-Founder and Chief Product Officer at Juricon.ai, Business Strategist and Fractional CXO at GRBA Consulting, and Chief Product Officer at BLOCK Software Solutions, among others. My leadership has consistently resulted in significant improvements in operational efficiency, product innovation, and market competitiveness.
          </p>
          <p className="text-lg mb-4 max-w-2xl mx-auto">
            With a proven track record of transforming startups into profitable businesses, I am passionate about leveraging my extensive operational expertise and strategic insight to deliver exceptional results. My commitment is to help businesses drive product growth, improve efficiency, and stay ahead of the competition.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Educational Background:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Executive Leadership and Business Management from Cornell University.</li>
            <li>Engineering Diploma in Networks Engineering from RCC Institute of Technology.</li>
          </ul>
          <p className="text-lg mb-4 max-w-2xl mx-auto">
            In my free time, I enjoy mountain biking and playing basketball. I also coach young kids in my community, fostering their love for the game and teaching them valuable life skills through sports.
          </p>
          <p className="text-lg mb-8 max-w-2xl mx-auto">
            I invite you to explore how my experience and strategic approach can elevate your business. Let’s connect and drive your business towards unparalleled growth and success.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk!
          </button>
        </div>
      </section>
    </div>
  );
};

export default About;