import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createClient } from 'contentful';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const client = createClient({
  space: 'y1v5nf75iar4', // Replace with your Contentful space ID
  accessToken: '3NusGvxAiDdu8NU3hmDdm3rlUIeqor3cY903K0yI3Ns', // Replace with your Contentful access token
});

const Blog = () => {
  const { pathname } = useLocation();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'article',
          order: '-fields.publishedDate',
        });
        const items = response.items;

        // Extract categories
        const allCategories = items.map((item) => item.fields.category);
        const uniqueCategories = [...new Set(allCategories)];

        setCategories(uniqueCategories);
        setPosts(items);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const filterPostsByCategory = (category) => {
    setSelectedCategory(category);
  };

  const filteredPosts = selectedCategory
    ? posts.filter((post) => post.fields.category === selectedCategory)
    : posts;

  const promotedPosts = posts.filter((post) => post.fields.promote);

  if (loading) {
    return <div className="container mx-auto p-4 bg-black text-white">Loading...</div>;
  }

  if (posts.length === 0) {
    return <div className="container mx-auto p-4 bg-black text-white">No blog posts available.</div>;
  }

  return (
    <div className="container mx-auto p-4 bg-black text-white">
      <div className="bg-white text-black p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center mb-4">From the Desk of a Seasoned Product Executive</h2>
        <p className="text-center mb-6">Explore insights and strategies from a seasoned product executive with over 20 years of experience launching successful products and driving growth at global tech companies.</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2">
            <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg mb-6">
              {promotedPosts[0] && (
                <>
                  {promotedPosts[0].fields.image && (
                    <img
                      src={promotedPosts[0].fields.image.fields.file.url}
                      alt={promotedPosts[0].fields.title}
                      className="w-full h-64 object-cover rounded-lg"
                    />
                  )}
                  <h3 className="text-2xl font-bold mt-4">{promotedPosts[0].fields.title}</h3>
                  <p className="mt-2">{promotedPosts[0].fields.excerpt}</p>
                  <Link to={`/blog/${promotedPosts[0].fields.slug}`} className="text-blue-500 hover:underline mt-4 inline-block">
                    Read More
                  </Link>
                </>
              )}
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Featured Articles</h3>
            {promotedPosts.slice(1).map((post) => (
              <div key={post.sys.id} className="flex mb-4">
                {post.fields.image && (
                  <img
                    src={post.fields.image.fields.file.url}
                    alt={post.fields.title}
                    className="w-24 h-24 object-cover rounded-lg mr-4"
                  />
                )}
                <div>
                  <h4 className="text-lg font-semibold">{post.fields.title}</h4>
                  <p className="text-sm text-gray-500">{post.fields.author} - {new Date(post.fields.publishedDate).toLocaleDateString()}</p>
                  <Link to={`/blog/${post.fields.slug}`} className="text-blue-500 hover:underline">
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex mt-6">
        <Sidebar
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={filterPostsByCategory}
        />
        <div className="flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPosts.map((post) => (
            <div key={post.sys.id} className="bg-gray-800 rounded-lg shadow-lg p-4">
              {post.fields.image && (
                <img
                  src={post.fields.image.fields.file.url}
                  alt={post.fields.title}
                  className="w-full h-48 object-cover rounded-t-lg"
                />
              )}
              <h3 className="text-2xl font-bold mt-4">{post.fields.title}</h3>
              <p className="mt-2">{post.fields.excerpt}</p>
              <Link to={`/blog/${post.fields.slug}`} className="text-blue-500 hover:underline mt-4 inline-block">
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
