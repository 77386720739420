import React, { useEffect } from 'react';
import productLeadershipCoachingImage from '../../../assets/product-leadership-coaching.jpg'; // Ensure this path is correct
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const ProductLeadershipCoaching = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="product-leadership-coaching font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${productLeadershipCoachingImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Product Leadership Coaching</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            Elevate your product leadership skills and accelerate your career trajectory. This personalized coaching program is designed for ambitious product professionals who aspire to become exceptional leaders and drive impactful results. Through one-on-one coaching sessions, we'll delve into your unique strengths and development areas, crafting a tailored plan to enhance your strategic thinking, decision-making, communication, and team-building capabilities. You'll gain valuable insights from my 20+ years of experience leading product teams at global tech companies, and we'll work together to overcome challenges, unlock your full potential, and achieve your career aspirations.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Who is it for:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Product Managers striving to advance to senior roles or transition into leadership positions.</li>
            <li>CPOs and Heads of Product seeking to refine their leadership skills and drive greater impact.</li>
            <li>Ambitious product professionals who want to expand their knowledge and skillset to stay ahead in the competitive tech landscape.</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What's achieved:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Enhanced Leadership Skills: Develop the strategic thinking, decision-making, communication, and team-building skills essential for effective product leadership.</li>
            <li>Career Advancement: Gain the knowledge and confidence to pursue senior leadership roles and achieve your career goals.</li>
            <li>Increased Impact: Drive greater impact within your organization by making more informed decisions, leading high-performing teams, and delivering successful products.</li>
            <li>Personalized Growth: Receive tailored guidance and support to address your specific challenges and development areas.</li>
            <li>Expanded Network: Connect with a seasoned product leader and gain access to a valuable network of industry professionals.</li>
          </ul>
          <p className="text-lg mb-8">
            By investing in this coaching program, you're investing in your future as a product leader. You'll gain the skills, knowledge, and confidence to navigate the complexities of the tech industry, lead high-performing teams, and deliver exceptional results.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default ProductLeadershipCoaching;
