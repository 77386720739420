import React, { useEffect } from 'react';
import operationalExcellenceImage from '../../../assets/operational-excellence.jpg'; // Ensure this path is correct
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const OperationalExcellence = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="operational-excellence font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${operationalExcellenceImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Operational Excellence and Optimization</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            Unlock the full potential of your operations and drive sustainable growth. I will help you analyze and streamline your business processes, identifying bottlenecks and inefficiencies. By implementing proven strategies and leveraging data-driven insights, we'll optimize your operations for maximum efficiency, cost reduction, and improved performance. We'll also focus on enhancing your supply chain, ensuring timely delivery, reducing inventory costs, and improving overall responsiveness. Additionally, I'll help you build high-performing teams, foster a culture of collaboration, and develop leadership skills within your organization, creating a foundation for long-term success.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Who is it for:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Businesses experiencing operational bottlenecks or inefficiencies.</li>
            <li>Companies seeking to reduce costs and improve profitability.</li>
            <li>Organizations looking to optimize their supply chain and inventory management.</li>
            <li>Leaders who want to build high-performing teams and foster a culture of collaboration.</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What's achieved:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Streamlined Processes: Elimination of bottlenecks and inefficiencies in your operations.</li>
            <li>Cost Reduction: Identification and implementation of cost-saving measures.</li>
            <li>Improved Efficiency: Optimization of resource utilization and increased productivity.</li>
            <li>Enhanced Supply Chain: Improved delivery times, reduced inventory costs, and increased responsiveness.</li>
            <li>High-Performing Teams: Development of strong teams with effective communication and collaboration.</li>
            <li>Strong Leadership: Cultivation of leadership skills and a culture of continuous improvement.</li>
          </ul>
          <p className="text-lg mb-8">
            By partnering with me, you'll gain a seasoned operational leader who can help you transform your operations into a well-oiled machine, driving efficiency, profitability, and sustainable growth.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default OperationalExcellence;

