import React, { useEffect } from 'react';
import strategicPlanningImage from '../../../assets/strategic-planning.jpg'; // Make sure you have this image or update the path accordingly
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const StrategicProductPlanning = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="strategic-product-planning font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${strategicPlanningImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Strategic Product Planning and Roadmapping</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            In today's dynamic market, a well-defined product strategy is essential for success. I will help you craft a clear and actionable product strategy that aligns with your business goals and sets you on a path to sustainable growth. Through meticulous market and competitive analysis, we'll identify untapped opportunities and potential risks, ensuring your product stands out in the crowded marketplace. Together, we'll build a prioritized product roadmap that outlines key features, timelines, and dependencies, guiding your team towards efficient execution and timely delivery. And to ensure a successful launch, we'll develop a comprehensive go-to-market (GTM) strategy that encompasses marketing, sales, and distribution, maximizing your product's reach and impact.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Who is it for:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Startups seeking to launch their first product and establish a strong market presence.</li>
            <li>Established businesses looking to expand their product portfolio or revitalize existing products.</li>
            <li>Companies facing challenges in aligning their product strategy with their overall business objectives.</li>
            <li>Organizations seeking to improve their product development processes and accelerate time-to-market.</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What's achieved:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Clear Product Vision: A well-articulated product vision that inspires and motivates your team.</li>
            <li>Comprehensive Product Strategy: A roadmap for success that outlines your target market, value proposition, and key initiatives.</li>
            <li>Prioritized Roadmap: A detailed plan that guides your product development efforts and ensures efficient resource allocation.</li>
            <li>Effective GTM Strategy: A launch plan that maximizes market penetration and drives early adoption.</li>
            <li>Competitive Advantage: A deep understanding of your competitive landscape and a differentiated product positioning.</li>
            <li>Data-Driven Decision Making: Insights from market and competitive analysis to inform your product strategy.</li>
            <li>Improved Time-to-Market: Streamlined product development processes that accelerate your time-to-market.</li>
          </ul>
          <p className="text-lg mb-8">
            By partnering with me, you'll gain a strategic product leadership partner who can help you navigate the complexities of product planning and roadmapping, ensuring your product is positioned for success in the market.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default StrategicProductPlanning;
