import React, { useState, useEffect } from 'react';
import { getCalApi } from "@calcom/embed-react";
import { Link } from 'react-router-dom';
import polaroidLogo from '../../assets/POLA.png';  
import punktLogo from '../../assets/PUNKT.png';
import cutLogo from '../../assets/CUT.png';
import juriconLogo from '../../assets/JC.png';
import newburnLogo from '../../assets/NWL.jpg';
import afLogo from '../../assets/AF-logo.jpg';
import samsungLogo from '../../assets/samsung-logo-white.jpg'
import telusLogo from '../../assets/Telus-Symbol.jpg'
import heroImage from '../../assets/hero.jpg';
import consultingImage from '../../assets/consulting.jpg'; // Add your images
import coachingImage from '../../assets/coaching.jpg'; // Add your images
import Slider from "react-slick";  // Import react-slick for carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  const [activeTab, setActiveTab] = useState('consulting');
  const [taglineIndex, setTaglineIndex] = useState(0);
  
  const taglines = [
    "Product Leadership That Delivers Results.",
    "Transform Your Product Vision into Market Success.",
    "Your Strategic Partner for Product Growth and Innovation.",
    "20+ Years of Product Leadership Expertise at Your Service.",
    "Unlock Your Product's Full Potential with Proven Strategies."
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setTaglineIndex((prevIndex) => (prevIndex + 1) % taglines.length);
    }, 4000); // Change tagline every 3 seconds
    return () => clearInterval(interval);
  }, [taglines.length]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  

  const testimonials = [
    {
      quote: "Ogi's expertise and support in growing my business have been invaluable. He truly cares about my success and provides clear strategies for business growth. I highly recommend Ogi Grba and his company.",
      name: "K. Joseph",
      company: "KKO Productions",
      location: "CA"
    },
    {
      quote: "Working with Ogi has been invaluable. He guided us during a pivotal business model shift, provided essential advice to early-stage founders, and connected us with valuable resources in technology and legal support. Highly recommended!",
      name: "N. Parkins",
      company: "Loaded Mone",
      location: "IL"
    },
    {
      quote: "Ogi Grba has been amazing to work with as we grow our firm. His experience and coaching have accelerated my ability to successfully scale my sales organization.",
      name: "L. Caldie",
      company: "Applied Frameworks",
      location: "CA"
    },
    {
      quote: "Ogi's expertise and support in growing my business have been invaluable. He truly cares about my success and provides clear strategies for business growth. I highly recommend Ogi Grba and his company.",
      name: "S. Eisentraut",
      company: "Transformational Business Coaching",
      location: "AZ"
    },
    {
      quote: "Ogi Grba has been instrumental in my business development. His expertise in finance, marketing, and time management is exceptional, and he helped me discover my true passions. If you're at a business crossroads, I highly recommend Ogi!",
      name: "R. Newburn",
      company: "Newburn Law",
      location: "CO"
    }
  ];

  const testimonialsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const logos = [
    { src: polaroidLogo, alt: 'POLAROID' },
    { src: punktLogo, alt: 'PUNKT' },
    {src: samsungLogo, alt: 'SAMSUNG'},
    {src: telusLogo, alt: 'TELUS'},
    { src: cutLogo, alt: 'CONNECT UP TECHNOLOGIES' },
    { src: juriconLogo, alt: 'JURICON' },
    { src: newburnLogo, alt: 'NEWBURN LAW' },
    { src: afLogo, alt: 'APPLIED FRAMEWORKS' }
  ];

  const logosSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const consultingServices = [
    {
      title: "Strategic Product Planning & Roadmapping",
      description: "Craft a winning product strategy and roadmap that aligns with your business goals and sets you on a path to success."
    },
    {
      title: "Pricing Strategy",
      description: "Unlock your product's full revenue potential with a data-driven pricing strategy that maximizes profitability and resonates with your customers."
    },
    {
      title: "Product Development & Management",
      description: "Bring your product vision to life with expert guidance and agile methodologies, from ideation to launch and beyond."
    },
    {
      title: "Operational Excellence",
      description: "Streamline your operations, reduce costs, and achieve sustainable growth through process optimization and data-driven insights."
    },
    {
      title: "Digital Transformation",
      description: "Navigate the digital landscape with confidence. Leverage emerging technologies and innovative solutions to drive growth and gain a competitive edge."
    }
  ];

  const coachingServices = [
    {
      title: "Fractional CxO (CPO/COO) Services",
      description: "Gain the expertise of a seasoned product executive without the commitment of a full-time hire. Scale your product strategy and operational efficiency with flexible, part-time leadership."
    },
    {
      title: "Executive Coaching",
      description: "Unlock your full leadership potential. Overcome challenges, achieve your goals, and drive impactful results with personalized executive coaching."
    },
    {
      title: "Product Leadership Coaching",
      description: "Elevate your product leadership skills and accelerate your career. Gain the knowledge and confidence to lead high-performing teams and deliver exceptional products."
    }
  ];

  const renderServices = (services) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
      {services.map((service, index) => (
        <div key={index} className="service-item bg-gray-800 shadow rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105">
          <div className="p-6">
            <h4 className="text-xl font-heading font-semibold text-white mb-2">{service.title}</h4>
            <p className="text-white">{service.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="home font-body bg-black text-white">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-screen flex items-center justify-center" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50 transition-opacity duration-1000"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4 animate-fade-in-up">
          <h1 className="text-5xl md:text-6xl font-heading font-bold text-white">
            {taglines[taglineIndex]}
          </h1><br></br>
          <h2 className="text-2xl md:text-2xl font-heading font-bold text-white">20+ Years of Driving Growth and Innovation in the Tech Industry</h2>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk!
          </button>
        </div>
      </section>

      {/* Service Overview Section */}
      <section className="services py-20 bg-black">
        <div className="container mx-auto max-w-screen-lg text-center">
          <h2 className="text-5xl font-heading font-bold text-white mb-6 animate-fade-in-up">Our Services</h2>
          <div className="flex justify-center mb-8">
            <button
              className={`px-4 py-2 mx-2 ${activeTab === 'consulting' ? 'bg-gray-800 text-white' : 'bg-gray-600 text-gray-400'} rounded transition-colors duration-300`}
              onClick={() => setActiveTab('consulting')}
            >
              Consulting Services
            </button>
            <button
              className={`px-4 py-2 mx-2 ${activeTab === 'coaching' ? 'bg-gray-800 text-white' : 'bg-gray-600 text-gray-400'} rounded transition-colors duration-300`}
              onClick={() => setActiveTab('coaching')}
            >
              Coaching & Fractional CxO
            </button>
          </div>
          <div className="relative mb-8">
            {activeTab === 'consulting' && (
              <>
                <img src={consultingImage} alt="Consulting" className="w-full h-60 object-cover rounded-lg" />
                <button 
                  data-cal-namespace="" 
                  data-cal-link="ogrba/explore" 
                  data-cal-config='{"layout":"month_view"}' 
                  className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded transition-colors duration-300">
                  Let’s talk! Book Your Free Strategy Session! 
                </button>
              </>
            )}
            {activeTab === 'coaching' && (
              <>
                <img src={coachingImage} alt="Coaching" className="w-full h-60 object-cover rounded-lg" />
                <button 
                  data-cal-namespace="" 
                  data-cal-link="ogrba/explore" 
                  data-cal-config='{"layout":"month_view"}' 
                  className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded transition-colors duration-300">
                  Let’s talk! Book Your Free Coaching Consultation! 
                </button>
              </>
            )}
          </div>
          {activeTab === 'consulting' && renderServices(consultingServices)}
          {activeTab === 'coaching' && renderServices(coachingServices)}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials py-20 bg-black">
        <div className="container mx-auto max-w-screen-lg text-center">
          <h2 className="text-3xl font-heading font-bold text-white mb-6 animate-fade-in-up">Client Success Stories</h2>
          <Slider {...testimonialsSettings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="p-6 bg-gray-800 shadow rounded mx-4 transform transition-transform duration-300 hover:scale-105">
                <p className="text-xl text-white italic mb-4">&quot;{testimonial.quote}&quot;</p>
                <p className="text-white font-heading font-semibold mt-4">{testimonial.name}</p>
                <p className="text-white">{testimonial.company}, {testimonial.location}</p>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Logo Carousel Section */}
      <section className="logos py-20 bg-black">
        <div className="container mx-auto max-w-screen-lg text-center">
          <h2 className="text-3xl font-heading font-bold text-white mb-6 animate-fade-in-up">Companies we worked with</h2>
          <Slider {...logosSettings}>
            {logos.map((logo, index) => (
              <div key={index} className="flex justify-center items-center transform transition-transform duration-300 hover:scale-105">
                <img src={logo.src} alt={logo.alt} className="h-12 max-w-full" />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* About Section Teaser */}
      <section className="about-teaser py-20 bg-black">
        <div className="container mx-auto max-w-screen-lg text-center">
          <h2 className="text-3xl font-heading font-bold text-white mb-6 animate-fade-in-up">About Us</h2>
          <p className="text-white mb-6">With over 20 years of experience leading product teams and driving growth at global tech companies, I'm passionate about helping businesses achieve their full potential. Learn more about my expertise and approach.</p>
          <Link to="/about">
            <button className="px-4 py-2 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded transition-colors duration-300">Learn More</button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
