import React, { useEffect } from 'react';
import digitalTransformationImage from '../../../assets/digital-transformation.jpg'; // Ensure this path is correct
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const DigitalTransformation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="digital-transformation font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${digitalTransformationImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Digital Transformation and Innovation</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            In today's rapidly evolving technological landscape, digital transformation is no longer a luxury but a necessity for businesses to thrive. I will help you navigate this complex journey by developing a comprehensive digital strategy that aligns with your business objectives and leverages emerging technologies. We'll assess your current technology infrastructure, identify areas for improvement, and implement new systems and processes to drive innovation and efficiency. Through workshops and training programs, we'll foster a culture of innovation within your organization, empowering your team to embrace change and generate new ideas. We'll also explore the integration of cutting-edge technologies like IoT, blockchain, and AI to unlock new growth opportunities and give you a competitive edge.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Who is it for:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Businesses seeking to embrace digital transformation and leverage technology for growth.</li>
            <li>Companies looking to modernize their technology infrastructure and processes.</li>
            <li>Organizations wanting to foster a culture of innovation and stay ahead of the competition.</li>
            <li>Leaders who want to understand and leverage emerging technologies like IoT, blockchain, and AI.</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What's achieved:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Comprehensive Digital Strategy: A roadmap for your digital transformation journey, aligned with your business goals.</li>
            <li>Modernized Technology: Upgraded systems and processes that drive efficiency and innovation.</li>
            <li>Culture of Innovation: A workforce that embraces change, generates new ideas, and drives continuous improvement.</li>
            <li>Competitive Advantage: Early adoption of emerging technologies to gain a competitive edge.</li>
            <li>New Growth Opportunities: Identification and implementation of innovative solutions that unlock new revenue streams.</li>
          </ul>
          <p className="text-lg mb-8">
            By partnering with me, you'll gain a digital transformation expert who can guide you through this exciting journey, ensuring your business is equipped to thrive in the digital age.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default DigitalTransformation;

