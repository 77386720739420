import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import coachingImage from '../../../assets/coaching.jpg';
import { useLocation } from 'react-router-dom';


const CoachingServices = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="coaching-services font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${coachingImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Coaching & Fractional CxO Services</h1>
          <p className="text-2xl mt-4">Unlocking Leadership Potential and Driving Operational Excellence</p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            As a seasoned product executive and business leader, I offer a range of coaching and fractional CXO services designed to empower individuals and organizations to reach their full potential. Whether you're an aspiring product leader seeking to enhance your skills or a company in need of experienced leadership on a flexible basis, I can provide the guidance and support you need to succeed.
          </p>
          <p className="text-lg mb-4">
            My coaching programs are tailored to your specific needs and goals, focusing on developing your strategic thinking, decision-making, communication, and team-building capabilities. I draw on my extensive experience leading product teams and driving growth at global tech companies to provide you with actionable insights and practical strategies.
          </p>
          <p className="text-lg mb-4">
            As a Fractional CPO or COO, I offer on-demand leadership and expertise to help you navigate critical transitions, fill temporary leadership gaps, or accelerate specific initiatives. I can help you develop and execute product strategies, optimize your operations, and drive sustainable growth.
          </p>
          <p className="text-lg mb-8">
            Invest in your leadership development and operational excellence. Explore my coaching and fractional CXO services today.
          </p>
        </div>
      </section>
      
      {/* Services List Section */}
      <section className="services-list py-20 bg-gray-900 text-center">
        <div className="container mx-auto max-w-screen-lg">
          <h2 className="text-3xl font-heading font-bold text-white mb-6">Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Fractional CPO/COO Services</h3>
              <p className="text-white mb-4">Provides experienced leadership and guidance on a flexible, part-time basis.</p>
              <Link to="/coaching-services/fractional-cpo-coo" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Executive Coaching</h3>
              <p className="text-white mb-4">Enhances leadership skills and helps executives achieve their full potential.</p>
              <Link to="/coaching-services/executive-coaching" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
            <div className="service-item bg-gray-800 shadow rounded-lg overflow-hidden p-6">
              <h3 className="text-xl font-heading font-semibold text-white mb-2">Product Leadership Coaching</h3>
              <p className="text-white mb-4">Equips product professionals with the skills and knowledge to lead high-performing teams and deliver exceptional products.</p>
              <Link to="/coaching-services/product-leadership-coaching" className="text-blue-400 hover:text-blue-200">Learn more</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoachingServices;
