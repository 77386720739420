import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // Ensure Link is imported here
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const client = createClient({
  space: 'y1v5nf75iar4', // Replace with your Contentful space ID
  accessToken: '3NusGvxAiDdu8NU3hmDdm3rlUIeqor3cY903K0yI3Ns', // Replace with your Contentful access token
});

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [suggestedPosts, setSuggestedPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'article',
          'fields.slug': slug,
        });
        if (response.items.length > 0) {
          setPost(response.items[0]);
        } else {
          console.error('No post found for the given slug');
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  useEffect(() => {
    if (post) {
      const fetchSuggestedPosts = async () => {
        try {
          const response = await client.getEntries({
            content_type: 'article',
            'fields.category': post.fields.category,
            'fields.slug[ne]': slug,
            limit: 3,
          });
          setSuggestedPosts(response.items);
        } catch (error) {
          console.error('Error fetching suggested blog posts:', error);
        }
      };

      fetchSuggestedPosts();
    }
  }, [post, slug]);

  if (loading) {
    return <div className="container mx-auto p-4 bg-black text-white">Loading...</div>;
  }

  if (!post) {
    return <div className="container mx-auto p-4 bg-black text-white">No post found.</div>;
  }

  return (
    <div className="container mx-auto p-4 bg-black text-white">
      <div className="bg-white text-black p-8 rounded-lg shadow-lg">
        <h1 className="text-5xl font-bold text-center mb-6">{post.fields.title}</h1>
        {post.fields.image && (
          <div className="flex justify-center mb-4">
            <img
              src={post.fields.image.fields.file.url}
              alt={post.fields.title}
              className="w-full max-w-3xl h-auto object-cover rounded"
            />
          </div>
        )}
        <div className="prose prose-lg prose-black mx-auto">
          {documentToReactComponents(post.fields.content)}
        </div>
      </div>
      <div className="mt-8">
        <h3 className="text-2xl font-bold mb-4">Suggested Blogs</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {suggestedPosts.map((post) => (
            <div key={post.sys.id} className="bg-gray-800 rounded-lg shadow-lg p-4">
              {post.fields.image && (
                <img
                  src={post.fields.image.fields.file.url}
                  alt={post.fields.title}
                  className="w-full h-48 object-cover rounded-t-lg"
                />
              )}
              <h3 className="text-2xl font-bold mt-4">{post.fields.title}</h3>
              <p className="mt-2">{post.fields.excerpt}</p>
              <Link to={`/blog/${post.fields.slug}`} className="text-blue-500 hover:underline mt-4 inline-block">
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPost;

