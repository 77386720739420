import React, { useEffect } from 'react';
import pricingStrategyImage from '../../../assets/pricing-strategy.jpg'; // Correct path
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const PricingStrategy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="pricing-strategy font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${pricingStrategyImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Pricing Strategy</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            Develop a pricing strategy that not only covers costs but also drives profitability and aligns seamlessly with your product's unique value proposition. We'll delve deep into your target market, analyzing customer behavior and willingness to pay to pinpoint the optimal price points that resonate with your audience while maximizing your revenue potential.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Who is it for:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Tech startups and innovators who are ready to launch or scale their products and want to ensure they are capturing the maximum value from their innovation.</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What's achieved:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Optimal Pricing: Identify the ideal price points that balance customer affordability with your revenue goals.</li>
            <li>Value Maximization: Capture the full value of your product by understanding what customers are willing to pay.</li>
            <li>Competitive Advantage: Develop a pricing strategy that differentiates you from competitors and positions your product for success.</li>
            <li>Sustainable Profitability: Ensure your pricing model supports long-term profitability and growth.</li>
            <li>Customer Insights: Gain a deeper understanding of your target market and their purchasing behavior.</li>
          </ul>
          <p className="text-lg mb-8">
            By strategically aligning your pricing with your product's value and your customers' needs, you'll not only drive revenue but also foster customer loyalty and build a sustainable business.
          </p>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default PricingStrategy;
