import React, { useEffect } from 'react';
import executiveCoachingImage from '../../../assets/executive-coaching.jpg'; // Ensure this path is correct
import { getCalApi } from "@calcom/embed-react";
import { useLocation } from 'react-router-dom';

const ExecutiveCoaching = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        "styles": { "branding": { "brandColor": "#ffffff" } },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="executive-coaching font-body bg-black text-white min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: `url(${executiveCoachingImage})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dim overlay */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-5xl font-heading font-bold">Executive Coaching</h1>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-black text-center">
        <div className="container mx-auto max-w-screen-lg">
          <p className="text-lg mb-4">
            Develop your strategic thinking, decision-making, communication, and team-building skills through personalized coaching. Whether you're a new executive or a seasoned leader, I can help you reach your full potential and achieve your professional goals.
          </p>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">What I offer:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>One-on-one coaching sessions</li>
            <li>Personalized development plans</li>
            <li>Tools and resources for continuous improvement</li>
            <li>Ongoing support and guidance</li>
          </ul>
          <h2 className="text-3xl font-heading font-bold text-white mb-4">Benefits of executive coaching:</h2>
          <ul className="list-disc list-inside text-left max-w-2xl mx-auto mb-4">
            <li>Improved leadership skills</li>
            <li>Enhanced performance</li>
            <li>Personal growth and development</li>
            <li>Increased confidence and resilience</li>
          </ul>
          <button 
            data-cal-namespace="" 
            data-cal-link="ogrba/explore" 
            data-cal-config='{"layout":"month_view"}' 
            className="mt-6 px-6 py-3 bg-white hover:bg-gray-300 text-black font-heading font-semibold rounded text-xl transition-colors duration-300">
            Let's Talk! Book a Free Strategy Session
          </button>
        </div>
      </section>
    </div>
  );
};

export default ExecutiveCoaching;

